import React from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import { ToWords } from "to-words";
import { Button } from "@mui/material";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import moment from "moment";

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "Rs.",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const twoDecimal = (x) => {
  // Convert to a float and format to two decimal places
  const num = Number.parseFloat(x).toFixed(2);

  // Split the integer and decimal parts
  const [integerPart, decimalPart] = num.split(".");

  // Format the integer part with a proper regex for Indian numbering system
  const formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

  // Return the formatted value with the decimal part
  return `${formattedInteger}.${decimalPart}`;
};

const handleNumberToWOrdCurrency = (v) => {
  // Remove any commas from the number string
  const numberWithoutCommas = v.toString().replace(/,/g, "");

  console.log(
    "⛔ ➡️ file: GeneratePdf.jsx:48 ➡️ numberWithoutCommas:",
    numberWithoutCommas
  );

  return toWords.convert(numberWithoutCommas);
};

function roundUpToInteger(value) {
  const roundedValue = Math.ceil(value);
  const num = roundedValue.toFixed(2);
  // Split the integer and decimal parts
  const [integerPart, decimalPart] = num.split(".");

  // Format the integer part with a proper regex for Indian numbering system
  const formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

  // Return the formatted value with the decimal part
  return `${formattedInteger}.${decimalPart}`;
}

function calculateRoundingDifference(value) {
  const roundedValue = Math.ceil(value);
  const difference = roundedValue - value;

  return difference.toFixed(2);
}

const PurchaseOrderPDF = ({
  poDetails,
  projectDetails,
  paymentList,
  companyLogo,
  classificationData,
}) => {
  const [isIgstPdf, setIsIgstPdf] = React.useState(false);

  console.log(
    "🚀💻 ~ file: GeneratePdf.jsx:50 ~ PurchaseOrderPDF ~ paymentList:",
    classificationData
  );

  React.useEffect(() => {
    if (poDetails) {
      setIsIgstPdf(
        poDetails.total_cgst == 0 &&
          poDetails.total_sgst == 0 &&
          poDetails.total_igst > 0
      );
    }
  }, [poDetails]);

  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    let pageNumber = 1;
    let page = pdfDoc.addPage([650, 950]);
    const { width, height } = page.getSize();
    const fontSize = 10;
    const boldFontSize = 12;
    const margin = 40;
    const smallBoldFontSize = 11;
    const smallFontSize = 10;
    const headerFooterMargin = 60;
    const headerHeight = 50;
    let currentY = height - headerFooterMargin;

    // Load fonts
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const logoBytes = await fetch(companyLogo).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoBytes);
    const logoDims = logoImage.scale(0.2);

    const drawHeader = (page) => {
      const { width, height } = page.getSize();
      page.drawText(
        poDetails.order_type === "1" ? "Purchase Order" : "Work Order",
        {
          x: width / 2 - 80,
          y: height - headerHeight + 10,
          size: 24,
          font: boldFont,
          color: rgb(0, 0.4, 0.8),
        }
      );
      page.drawText(` ${poDetails?.po_no} `, {
        x: width - margin - 50,
        y: height - headerHeight + 10,
        size: 11,
        boldFont,
        color: rgb(0, 0.4, 0.8),
      });
      page.drawLine({
        start: { x: 0, y: height - headerHeight },
        end: { x: width, y: height - headerHeight },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
    };

    const drawFooter = (page, pageNumber) => {
      const { width, height } = page.getSize();
      page.drawLine({
        start: { x: margin, y: margin },
        end: { x: width - margin, y: margin },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
      page.drawText(`KG Build Tech`, {
        x: width / 2 - 10,
        y: margin - 20,
        size: fontSize,
        font,
        color: rgb(0, 0.4, 0.8),
      });
      page.drawText(` • ${pageNumber} • `, {
        x: width - margin - 50,
        y: margin - 20,
        size: fontSize,
        boldFont,
        color: rgb(0, 0.4, 0.8),
      });
    };

    const drawText = (text, x, y, size, font, options = {}) => {
      page.drawText(text.toString(), { x, y, size, font, ...options });
    };

    const drawLine = (start, end, options = {}) => {
      page.drawLine({ start, end, ...options });
    };

    const addPage = () => {
      page = pdfDoc.addPage([650, 950]);
      const { width, height } = page.getSize();
      pageNumber++;
      drawHeader(page);
      drawFooter(page, pageNumber);
      currentY = height - headerFooterMargin - headerHeight - 20; // Adjust for header and margin
      return page;
    };

    const checkAndAddNewPage = (heightNeeded) => {
      if (currentY - heightNeeded < margin) {
        page = addPage();
        currentY = height - margin - headerHeight - 20; // Adjust for header and margin
      }
    };

    // Header
    // drawText(
    //   poDetails.order_type == "1" ? "Purchase Order" : "Work Order",
    //   width / 2 - 50,
    //   currentY,
    //   18,
    //   boldFont,
    //   {
    //     color: rgb(0, 0.4, 0.8),
    //   }
    // );
    // currentY -= 20;
    // drawLine(
    //   { x: 0, y: currentY },
    //   { x: width, y: currentY },
    //   { thickness: 1, color: rgb(0, 0, 0) }
    // );
    drawHeader(page);
    drawFooter(page, pageNumber);
    currentY -= 20;

    // currentY -= 20;

    // Company Logo
    page.drawImage(logoImage, {
      x: margin + 20,
      y: currentY - 80,
      width: 120,
      height: 50,
    });
    // currentY -= 120;

    // Company Info
    const companyInfoYStart = currentY;
    const companyInfoXStart = width - margin;

    const rightAlignText = (text, y, size, font, color) => {
      const textWidth = font.widthOfTextAtSize(text, size);
      drawText(text, companyInfoXStart - textWidth, y, size, font, color);
    };

    const companyNameColor = "blue"; // Color specifically for company_Name
    const defaultColor = "black"; // Default color for other texts

    drawText(
      poDetails.company_Name || "",
      companyInfoXStart -
        font.widthOfTextAtSize(poDetails.company_Name, fontSize + 6),
      companyInfoYStart,
      fontSize + 6,
      boldFont,
      { color: rgb(0, 0.4, 0.8) }
    );

    rightAlignText(
      poDetails.company_address || "",
      companyInfoYStart - 30,
      fontSize + 1,
      font,
      defaultColor
    );

    rightAlignText(
      `${poDetails.company_city || ""} - ${poDetails.company_pincode || ""}, ${
        poDetails.company_state || ""
      } India`,
      companyInfoYStart - 50,
      fontSize + 1,
      font,
      defaultColor
    );

    rightAlignText(
      `GST NO: ${poDetails.gst_no || ""}`,
      companyInfoYStart - 70,
      fontSize + 1,
      font,
      defaultColor
    );

    // rightAlignText(
    //   poDetails.company_primary_contact || "",
    //   companyInfoYStart - 70,
    //   fontSize,
    //   font
    // );
    // rightAlignText(
    //   poDetails.company_emailId || "",
    //   companyInfoYStart - 90,
    //   fontSize,
    //   font
    // );
    rightAlignText(
      poDetails.company_website || "",
      companyInfoYStart - 90,
      fontSize + 1,
      font
    );

    // drawText(
    //   poDetails.company_Name || "",
    //   companyInfoXStart,
    //   companyInfoYStart,
    //   fontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.company_address || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 30,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   `${poDetails.company_city || ""} - ${poDetails.company_pincode || ""}, ${
    //     poDetails.company_state || ""
    //   } India`,
    //   companyInfoXStart,
    //   companyInfoYStart - 50,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   poDetails.company_primary_contact || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 70,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   poDetails.company_emailId || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 90,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   poDetails.company_website || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 110,
    //   fontSize,
    //   font
    // );
    currentY -= 120;
    drawLine(
      { x: 0, y: currentY },
      { x: width, y: currentY },
      { thickness: 1, color: rgb(0, 0, 0) }
    );
    currentY -= 40;

    // Utility function to split text into lines of specified length
    function splitTextIntoLines(text, maxLength) {
      const words = text.split(" ");
      let lines = [];
      let currentLine = "";

      words.forEach((word) => {
        if ((currentLine + word).length <= maxLength) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      });

      if (currentLine) {
        lines.push(currentLine);
      }

      return lines;
    }

    // Function to draw text with line wrapping
    function drawTextWithWrapping(
      text,
      x,
      y,
      maxLength,
      fontSize,
      font,
      options = {}
    ) {
      const lines = splitTextIntoLines(text, maxLength);
      lines.forEach((line, index) => {
        drawText(line, x, y - index * 20, fontSize, font, options);
      });
      return lines.length * 20; // Return total height occupied by text
    }

    // Vendor Info
    const vendorYStart = currentY;
    const vendorXStart = margin;

    drawText(
      "Bill To:",
      vendorXStart,
      vendorYStart,
      smallBoldFontSize + 3,
      boldFont
    );

    let offsetY = 20;

    const vendorName = poDetails.vendor_name || "";
    const words = vendorName.split(" ");
    let firstLine = words.slice(0, 3).join(" ");
    let secondLine = words.slice(3).join(" ");

    offsetY += drawTextWithWrapping(
      firstLine,
      vendorXStart,
      vendorYStart - offsetY,
      20,
      smallFontSize,
      boldFont,
      { color: rgb(0, 0.4, 0.8) }
    );

    if (secondLine) {
      offsetY += drawTextWithWrapping(
        secondLine,
        vendorXStart,
        vendorYStart - offsetY,
        20,
        smallFontSize,
        boldFont,
        { color: rgb(0, 0.4, 0.8) }
      );
    }

    offsetY += drawTextWithWrapping(
      poDetails.vendor_mobile || "",
      vendorXStart,
      vendorYStart - offsetY,
      20,
      smallFontSize,
      font
    );
    offsetY += drawTextWithWrapping(
      `${poDetails.vendor_street || ""},`,
      vendorXStart,
      vendorYStart - offsetY,
      20,
      smallFontSize,
      font
    );
    offsetY += drawTextWithWrapping(
      `${poDetails.vendor_city || ""}, ${poDetails.vendor_pincode || ""}, ${
        poDetails.vendor_state || ""
      }, India`,
      vendorXStart,
      vendorYStart - offsetY,
      20,
      smallFontSize,
      font
    );
    offsetY += drawTextWithWrapping(
      "GSTIN :",
      vendorXStart,
      vendorYStart - offsetY,
      20,
      smallBoldFontSize,
      boldFont
    );
    offsetY += drawTextWithWrapping(
      poDetails.vendor_gst || "",
      vendorXStart + 50,
      vendorYStart - (offsetY - 20),
      20,
      smallFontSize,
      font
    );

    // ProjectDetails
    const projectDetailsXStart = margin + 170;
    let projectOffsetY = 20;

    drawText(
      "Place of Supply:",
      projectDetailsXStart,
      vendorYStart,
      smallBoldFontSize + 5,
      boldFont
    );

    const projectName = projectDetails.name || "";
    const pwords = projectName.split(" ");
    let firstpLine = pwords.slice(0, 3).join(" ");
    let secondpLine = pwords.slice(3).join(" ");

    // projectOffsetY += drawTextWithWrapping(
    //   projectDetails.name || "",
    //   projectDetailsXStart,
    //   vendorYStart - projectOffsetY,
    //   20,
    //   smallFontSize,
    //   boldFont,
    //   { color: rgb(0, 0.4, 0.8) }
    // );

        projectOffsetY += drawTextWithWrapping(
          firstpLine,
          projectDetailsXStart,
      vendorYStart - projectOffsetY,
      20,
      smallFontSize,
      boldFont,
      { color: rgb(0, 0.4, 0.8) }
        );

        if (secondpLine) {
          projectOffsetY += drawTextWithWrapping(
            secondpLine,
            projectDetailsXStart,
            vendorYStart - projectOffsetY,
            20,
            smallFontSize,
            boldFont,
            { color: rgb(0, 0.4, 0.8) }
          );
        }
    projectOffsetY += drawTextWithWrapping(
      projectDetails.project_address || "",
      projectDetailsXStart,
      vendorYStart - projectOffsetY,
      20,
      smallFontSize,
      font
    );
    projectOffsetY += drawTextWithWrapping(
      `${projectDetails.city || ""},`,
      projectDetailsXStart,
      vendorYStart - projectOffsetY,
      20,
      smallFontSize,
      font
    );
    projectOffsetY += drawTextWithWrapping(
      `${projectDetails.state || ""}, ${projectDetails.pincode || ""}`,
      projectDetailsXStart,
      vendorYStart - projectOffsetY,
      20,
      smallFontSize,
      font
    );

    // currentY -= 140;

    // Purchase Order Info
    const poInfoYStart = currentY;
    const poInfoXStart = width - margin - 250 + 20;
    drawText(
      `Purchase Order No:`,
      poInfoXStart,
      poInfoYStart,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.po_no || "",
      poInfoXStart + 150,
      poInfoYStart,
      smallFontSize,
      boldFont,
      { color: rgb(0, 0.4, 0.8) }
    );
    drawText(
      `Mode/Terms of Payment:`,
      poInfoXStart,
      poInfoYStart - 20,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.payment_terms || "Immediate",
      poInfoXStart + 150,
      poInfoYStart - 20,
      smallFontSize,
      font
    );
    drawText(
      `Supplier Ref./Quotation No:`,
      poInfoXStart,
      poInfoYStart - 40,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.quotation_no || "",
      poInfoXStart + 150,
      poInfoYStart - 40,
      smallFontSize,
      font
    );
    drawText(
      `Packing Charge:`,
      poInfoXStart,
      poInfoYStart - 60,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.packing_charge || "0",
      poInfoXStart + 150,
      poInfoYStart - 60,
      smallFontSize,
      font
    );
    drawText(
      `Purchase Classification:`,
      poInfoXStart,
      poInfoYStart - 80,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      classificationData || "",
      poInfoXStart + 150,
      poInfoYStart - 80,
      smallFontSize,
      font
    );
    drawText(
      `Purchase Date:`,
      poInfoXStart,
      poInfoYStart - 100,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.po_date || "",
      poInfoXStart + 150,
      poInfoYStart - 100,
      smallFontSize,
      font
    );
    drawText(
      `Site Engineer:`,
      poInfoXStart,
      poInfoYStart - 120,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.site_eng_name || "",
      poInfoXStart + 150,
      poInfoYStart - 120,
      smallFontSize,
      font
    );
    drawText(
      `Site Engineer Contact:`,
      poInfoXStart,
      poInfoYStart - 140,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.site_eng_contact || "",
      poInfoXStart + 150,
      poInfoYStart - 140,
      smallFontSize,
      font
    );
    currentY -= 150;

    // Table Header
    const tableYStart = currentY - 70;

    const tableHeaders = [
      "S.No",
      "Description of Goods",
      "Qty.(nos.)",
      "Rate(Rs)",
      "Amount.(Rs)",
      "GST",
      isIgstPdf ? "IGST" : "CGST",
      !isIgstPdf ? "SGST" : undefined,
      "Total Amt. + GST(Rs)",
    ];

    const tableXPositions = [
      margin - 30,
      margin + 10,
      margin + 140,
      margin + 200,
      margin + 260,
      margin + 340,
      isIgstPdf ? margin + 390 : margin + 380,
      !isIgstPdf ? margin + 430 : undefined,
      margin + (isIgstPdf ? 490 : 490),
    ];

    tableHeaders.forEach((header, index) => {
      if (header) {
        // Check to ensure header is not undefined
        drawText(
          header,
          tableXPositions[index],
          tableYStart,
          smallBoldFontSize,
          boldFont
        );
      }
    });

    drawLine(
      { x: 0, y: tableYStart - 10 },
      { x: width, y: tableYStart - 10 },
      { thickness: 2, color: rgb(0, 0, 0) }
    );
    currentY = tableYStart - 30;

    // Table Rows
    const tableRowHeight = 20;

    // Adjust drawing for payment list
    paymentList.forEach((item, index) => {
      checkAndAddNewPage(tableRowHeight);

      drawText(index + 1, tableXPositions[0], currentY, smallFontSize, font);

      // Handle wrapping for item_name
      let offsetY = drawTextWithWrapping(
        item.item_name || "",
        tableXPositions[1],
        currentY,
        20,
        smallFontSize,
        font,
        { maxWidth: 120 }
      );

      // Draw quantity
      drawText(
        twoDecimal(item.quantity) || "",
        tableXPositions[2],
        currentY - offsetY + 20,
        smallFontSize,
        font
      );

      // Draw amount
      const amountText = twoDecimal(item.amount) || "";
      const amountWidth = font.widthOfTextAtSize(amountText, smallFontSize);
      drawText(
        amountText,
        tableXPositions[3] + 40 - amountWidth,
        currentY - offsetY + 20,
        smallFontSize,
        font
      );

      // Draw total amount without GST
      const totalWithoutGSTText =
        twoDecimal(item.total_amount_without_gst) || "";
      const totalWithoutGSTWidth = font.widthOfTextAtSize(
        totalWithoutGSTText,
        smallFontSize
      );
      drawText(
        totalWithoutGSTText,
        tableXPositions[4] + 60 - totalWithoutGSTWidth,
        currentY - offsetY + 20,
        smallFontSize,
        font
      );

      // Draw GST
      drawText(
        `${item.gst}%` || "",
        tableXPositions[5],
        currentY - offsetY + 20,
        smallFontSize,
        font
      );

      if (isIgstPdf) {
        // Draw IGST
        drawText(
          twoDecimal(item.igst) || "",
          tableXPositions[6],
          currentY - offsetY + 20,
          smallFontSize,
          font
        );

        // Draw total amount with GST
        const totalWithGSTText = twoDecimal(item.total_amount_with_gst) || "";
        const totalWithGSTWidth = font.widthOfTextAtSize(
          totalWithGSTText,
          smallFontSize
        );
        drawText(
          totalWithGSTText,
          tableXPositions[8] + 90 - totalWithGSTWidth,
          currentY - offsetY + 20,
          smallFontSize,
          font
        );
      } else {
        // Draw CGST
        drawText(
          twoDecimal(item.cgst) || "",
          tableXPositions[6],
          currentY - offsetY + 20,
          smallFontSize,
          font
        );

        // Draw SGST
        drawText(
          twoDecimal(item.sgst) || "",
          tableXPositions[7],
          currentY - offsetY + 20,
          smallFontSize,
          font
        );

        // Draw total amount with GST
        const totalWithGSTText = twoDecimal(item.total_amount_with_gst) || "";
        const totalWithGSTWidth = font.widthOfTextAtSize(
          totalWithGSTText,
          smallFontSize
        );
        drawText(
          totalWithGSTText,
          tableXPositions[8] + 90 - totalWithGSTWidth,
          currentY - offsetY + 20,
          smallFontSize,
          font
        );
      }

      // Adjust Y-axis for the next row
      currentY -= tableRowHeight + offsetY;
    });

    // Total Calculation Section
    checkAndAddNewPage(100);

    if (currentY < 300) {
      addPage();
    }

    const totalSectionStartY = currentY + 10;
    drawLine(
      { x: margin, y: totalSectionStartY },
      { x: width - margin, y: totalSectionStartY },
      { thickness: 1, color: rgb(0, 0, 0) }
    );

    const totalAmountDetails = [
      {
        label: "Total Amount (Rs.):",
        value: `${twoDecimal(poDetails.total_without_gst) + "/-" || ""}`,
      },
      isIgstPdf
        ? {
            label: "IGST ('Rs.'):",
            value: `${twoDecimal(poDetails.total_igst) + "/-" || ""}`,
          }
        : {
            label: "CGST (Rs.):",
            value: `${twoDecimal(poDetails.total_cgst) + "/-" || ""}`,
          },
      !isIgstPdf
        ? {
            label: "SGST (Rs.):",
            value: `${twoDecimal(poDetails.total_sgst) + "/-" || ""}`,
          }
        : null,
      {
        label: "Transport GST:",
        value: `${twoDecimal(poDetails.transport_gst) || ""}%`,
      },
      {
        label: "Transport Amount (Rs.):",
        value: `${twoDecimal(poDetails.transport_charge) + "/-" || ""}`,
      },
      {
        label: "Total Amount with GST (Rs.):",
        value: `${twoDecimal(poDetails.total_with_gst) + "/-" || ""}`,
      },
      {
        label: "Rounded Off Value (Rs.):",
        value: `${calculateRoundingDifference(poDetails.total_with_gst)}/-`,
      },
      {
        label: "Total Payable Amount (Rs.):",
        value: `${roundUpToInteger(poDetails.total_with_gst)}/-`,
      },
    ].filter((detail) => detail !== null); // Remove null entries for non-applicable GST types

    // Adjust X positions if needed based on IGST or CGST/SGST
    const totalXPositions = [
      tableXPositions[4] + 10, // Label position for the first column
      tableXPositions[8] + 90, // Value position for the first column
    ];

    const rightAlignDetailsText = (text, xPosition, yPosition, size, font) => {
      const textWidth = font.widthOfTextAtSize(text, size);
      drawText(text, xPosition - textWidth, yPosition, size, font);
    };

    totalAmountDetails.forEach((detail, index) => {
      // Determine if the current item is the last one
      const isLastIndex = index === totalAmountDetails.length - 1;

      drawText(
        detail.label,
        totalXPositions[0],
        totalSectionStartY - 20 - index * 20,
        isLastIndex ? smallBoldFontSize + 5 : smallBoldFontSize,
        isLastIndex ? boldFont : boldFont // Change to regularFont if necessary
      );
      rightAlignDetailsText(
        detail.value.toString(),
        totalXPositions[1],
        totalSectionStartY - 20 - index * 20,
        isLastIndex ? smallBoldFontSize + 5 : smallBoldFontSize,
        isLastIndex ? boldFont : font // Apply bold font only to the last value
      );
    });

    currentY = totalSectionStartY - 140;

    // Terms and Conditions
    checkAndAddNewPage(200);
    // drawLine(
    //   { x: 0, y: currentY },
    //   { x: width, y: currentY },
    //   { thickness: 2, color: rgb(0, 0, 0) }
    // );
    currentY -= 50;

    drawText("Terms and Conditions:", margin, currentY, boldFontSize, boldFont);
    currentY -= 20;
    drawText(
      `${
        poDetails.terms
          ? poDetails.terms == null || poDetails.terms == ""
            ? "-"
            : poDetails.terms
          : "-"
      }`,
      margin,
      currentY - 10,
      fontSize,
      font,
      { maxWidth: width - 2 * margin }
    );

    const totalWordsYPosition = currentY - 60;
    drawText(
      "Prepared by",
      margin,
      totalWordsYPosition - 30,
      boldFontSize,
      boldFont
    );

    drawText(
      `Approved by`,
      margin + 250,
      totalWordsYPosition - 30,
      boldFontSize,
      boldFont,
      { maxWidth: width - 2 * margin }
    );

    if (currentY < margin + 20) {
      page = addPage();
      currentY = height - headerFooterMargin;
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(
      blob,
      `PurchaseOrder_${poDetails.po_no}_${moment().format(
        "DD-MM-YYYY hh:mm a"
      )}.pdf`
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SimCardDownloadRoundedIcon />}
        onClick={generatePDF}
        sx={{
          backgroundColor: "#2196f3",
          fontWeight: "bold",
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            backgroundColor: "#FFF",
            color: "#2196f3",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        Generate PDF
      </Button>
    </div>
  );
};

export default PurchaseOrderPDF;

// import { PDFDocument, rgb } from 'pdf-lib';

// export const generatePDF = async (poDetails, paymentList) => {
//   const pdfDoc = await PDFDocument.create();
//   const page = pdfDoc.addPage();
//   const { width, height } = page.getSize();
//   const fontSize = 12;

//   // Header
//   page.drawText(
//     poDetails.order_type === '1' ? 'Purchase Order' : 'Work Order',
//     {
//       x: 50,
//       y: height - 50,
//       size: 24,
//       color: rgb(0, 0.4, 0.8),
//     }
//   );

//   // Purchase Order Info
//   page.drawText(`Purchase Order No: ${poDetails.po_no}`, {
//     x: 50,
//     y: height - 100,
//     size: fontSize,
//   });

//   // Company Info
//   page.drawText(`${poDetails.company_Name}`, {
//     x: 400,
//     y: height - 100,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_address}`, {
//     x: 400,
//     y: height - 120,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_city} - ${poDetails.company_pincode}`, {
//     x: 400,
//     y: height - 140,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_state}, India`, {
//     x: 400,
//     y: height - 160,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_primary_contact} / ${poDetails.company_secondary_contact}`, {
//     x: 400,
//     y: height - 180,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_emailId}`, {
//     x: 400,
//     y: height - 200,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_website}`, {
//     x: 400,
//     y: height - 220,
//     size: fontSize,
//   });

//   // Vendor Info
//   page.drawText(`Vendor: ${poDetails.vendor_name}`, {
//     x: 50,
//     y: height - 250,
//     size: fontSize,
//   });

//   // Add more static text elements as necessary

//   // Table Headers
//   const headers = [
//     'S.No', 'Description of Goods', 'Quantity (nos.)', 'Amount (rs)',
//     'Total Amount (rs)', 'GST', poDetails.total_cgst == 0 && poDetails.total_sgst == 0 ? 'IGST' : 'CGST',
//     poDetails.total_cgst == 0 && poDetails.total_sgst == 0 ? '' : 'SGST', 'Total Amount + GST (INR)'
//   ];

//   let startY = height - 300;
//   let startX = 50;
//   let cellHeight = 20;
//   let cellPadding = 5;

//   headers.forEach((header, index) => {
//     if (header) {
//       page.drawText(header, { x: startX + (index * 70), y: startY, size: 10, color: rgb(0, 0, 0) });
//     }
//   });

//   // Table Rows
//   paymentList.forEach((row, rowIndex) => {
//     const y = startY - ((rowIndex + 1) * cellHeight);
//     const cells = [
//       (rowIndex + 1).toString(), row.item_name, `${twoDecimal(row.quantity)} ${row.unit}`,
//       twoDecimal(row.amount), twoDecimal(row.total_amount_without_gst), `${row.gst}%`,
//       row.igst != 0 ? twoDecimal(row.igst) : twoDecimal(row.cgst), row.igst != 0 ? '' : twoDecimal(row.sgst),
//       twoDecimal(row.total_amount_with_gst)
//     ];

//     cells.forEach((cell, cellIndex) => {
//       if (cell) {
//         page.drawText(cell, { x: startX + (cellIndex * 70), y: y - cellPadding, size: 10, color: rgb(0, 0, 0) });
//       }
//     });
//   });

//   // Add footer and other static text elements as necessary

//   const pdfBytes = await pdfDoc.save();
//   downloadPDF(pdfBytes);
// };

// const downloadPDF = (pdfBytes) => {
//   const blob = new Blob([pdfBytes], { type: 'application/pdf' });
//   const url = URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = 'purchase_order.pdf';
//   a.click();
//   URL.revokeObjectURL(url);
// };

// // Helper function to format numbers with two decimals
// const twoDecimal = (num) => {
//   return num.toFixed(2);
// };
