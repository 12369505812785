import React, { useEffect, useRef, useState } from "react";

//design imports from material
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

//form imports
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import {
  config,
  _numberOnly,
  projectMasterAPI,
  statusMasterAPI,
  vendorAPI,
  purchaseOrderAPI,
  termsAndConditionsAPI,
  userData,
  manageCompaniesAPI,
  purchaseOrderItemsAPI,
  handleHrMasterAPI,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { getValue } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// const names = ["KG Terms & Conditions", "Validity"];
let total = 0;
let cgst = 0;
let igst = 0;
let sgst = 0;
let totalAmount = 0;

function PurchaseOrderEdit() {
  const { POid } = useParams();
  const childRef = useRef();
  const navigate = useNavigate();

  // add field
  const [serviceList, setServiceList] = useState([
    { txtItemName: "", txtUnits: "" },
  ]);
  const [totalWithoutGST, setTotalWithoutGST] = useState(0);
  const [totalCgst, settotalCgst] = useState(0);
  const [totalSgst, setTotalSgst] = useState(0);
  const [totalIgst, setTotalIgst] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [txtSiteEng, setTextSiteEng] = useState("");
  const [deletedItems, setDeletedItems] = useState([]);

  const [deletedList, setDeletedList] = useState(null);

  // const handleServiceRemove = (index) => {
  //   const list = [...serviceList1];

  //   const newList = [...list];
  //   newList[index].intStatus = 1;
  //   setDeletedList([...newList]);
  //   console.log(newList, "new");

  //   list.splice(index, 1);

  //   console.log(list, "new");
  //   setServiceList1(list);
  // };

  const handleServiceRemove = (index) => {
    const list = [...serviceList1];
    const removedItem = list[index];
    const deletedId = removedItem.intID;

    console.log(
      "🚀💻 ~ file: PurchaseOrderEdit.jsx:112 ~ handleServiceRemove ~ deletedId:",
      deletedId
    );

    console.log(
      "🚀💻 ~ file: PurchaseOrderEdit.jsx:111 ~ handleServiceRemove ~ removedItem:",
      removedItem
    );

    removedItem.intStatus = 1;
    setDeletedItems((prevDeletedItems) => [...prevDeletedItems, deletedId]);

    list.splice(index, 1);
    setServiceList1(list);
    handleServiceChangeAfterRemove(list, removedItem);
  };

  const handleServiceChangeAfterRemove = (updatedList, removedItem) => {
    console.log(
      "🚀💻 ~ file: PurchaseOrderEdit.jsx:132 ~ handleServiceChangeAfterRemove ~ removedItem:",
      removedItem
    );

    let igst = 0;
    let cgst = 0;
    let sgst = 0;
    // let total = 0;
    let totalAmount = 0;

    updatedList.forEach((item) => {
      let tempGST = parseInt(item["intGST"]) / 2;

      item["intLineItemTotalWithoutGST"] =
        parseInt(item["intAmount"]) * parseInt(item["intQuantity"]);

      if (vendorDetails.state != "TamilNadu") {
        item["intIGST"] =
          (tempGST / 100) * item["intLineItemTotalWithoutGST"] +
          (tempGST / 100) * item["intLineItemTotalWithoutGST"];
        item["intCGST"] = 0;
        item["intSGST"] = 0;
      } else {
        item["intCGST"] = (tempGST / 100) * item["intLineItemTotalWithoutGST"];
        item["intSGST"] = (tempGST / 100) * item["intLineItemTotalWithoutGST"];
        item["intIGST"] = 0;
      }

      item["intTGST"] = item["intCGST"] + item["intSGST"] + item["intIGST"];

      item["intLineItemTotalWithGST"] =
        item["intLineItemTotalWithoutGST"] + item["intTGST"];

      igst += item["intIGST"] || 0;
      cgst += item["intCGST"] || 0;
      sgst += item["intSGST"] || 0;
      totalAmount += item["intLineItemTotalWithGST"] || 0;
      // total += item["intLineItemTotalWithoutGST"] || 0;
    });

    total = totalWithoutGST - removedItem.intLineItemTotalWithoutGST;
    setTotalWithoutGST(
      (prevTotal) => prevTotal - removedItem.intLineItemTotalWithoutGST
    );
    settotalCgst(cgst);
    setTotalSgst(sgst);
    setTotalIgst(igst);
    setTotalAmount(totalAmount + transportCharge);
  };

  const [unitsFlag, setUnitsFlag] = useState(false);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;

    console.log("⛔ ➡️ file: PurchaseOrderEdit.jsx:187 ➡️ name, value:", name, value);

    const list = [...serviceList1];

    console.log("⛔ ➡️ file: PurchaseOrderEdit.jsx:191 ➡️ list:", list);


    list[index][name] = value;
    // list[index]["intLineItemTotalWithGST"] = 20;

    let tempGST = parseInt(list[index]["intGST"]) / 2;
    list[index]["intLineItemTotalWithoutGST"] =
      parseFloat(list[index]["intAmount"]) *
      parseFloat(list[index]["intQuantity"]);

    if (vendorDetails.state != "TamilNadu") {
      list[index]["intIGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"] +
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    } else {
      list[index]["intCGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
      list[index]["intSGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    }

    list[index]["intTGST"] = list[index]["intCGST"] + list[index]["intSGST"];

    if (vendorDetails.state != "TamilNadu") {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] + list[index]["intIGST"];
    } else {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] +
        list[index]["intCGST"] +
        list[index]["intSGST"];
    }

    igst = 0;
    cgst = 0;
    sgst = 0;
    total = 0;
    totalAmount = 0;

    list.forEach((item) => {
      if (item.hasOwnProperty("intCGST")) {
        cgst = cgst + item["intCGST"];
      }

      if (item.hasOwnProperty("intSGST")) {
        sgst = sgst + item["intSGST"];
      }

      if (item.hasOwnProperty("intIGST")) {
        igst = igst + item["intIGST"];
      }

      if (item.hasOwnProperty("intLineItemTotalWithGST")) {
        totalAmount = totalAmount + item["intLineItemTotalWithGST"];
      }

      if (item.hasOwnProperty("intLineItemTotalWithoutGST")) {
        total = total + item["intLineItemTotalWithoutGST"];
      }
    });

    setTotalWithoutGST(total);
    setServiceList1(list);
    setDeletedList(list);

    // if (igst > 0) {
    //   settotalCgst(0);
    //   setTotalSgst(0);
    //   setTotalIgst(igst);
    // } else if (cgst > 0 && sgst > 0) {
    //   setTotalIgst(0);
    //   settotalCgst(cgst);
    //   setTotalSgst(sgst);
    // }
    settotalCgst(cgst);
    setTotalSgst(sgst);
    setTotalIgst(igst);

    // if (transportCharge > 0) {
    //   console.log("service change totalAm1", totalAmount)
    //   setTotalAmount(totalAmount + transportCharge);
    // } else {
    //   console.log("service change totalAm2", totalAmount)
    // }

    setTotalAmount(totalAmount + transportCharge);
  };

  const handleServiceAdd = () => {
    setServiceList1([...serviceList1, { txtItemName: "", txtUnits: "" }]);

    setDeletedList([...deletedList, { txtItemName: "", txtUnits: "" }]);
  };

  const validationSchema = Yup.object().shape({
    // txtCompanyName: Yup.string().required("Company Name is required"),
    // intVendorID: Yup.string().required("Vendor Name is required"),
    // intProjectID: Yup.string().required("Project Name is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);

  const { errors } = formState;

  const [PoDate, setPoDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  // Vendor List
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = vendorAPI;

      axios(config)
        .then(function (response) {
          // return;
          setVendorList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  let tempRefs = useRef(false);

  const [companyName, setCompanyName] = React.useState("");

  //Marital Details
  const [ProjectNameList, setProjectNameList] = useState([]);
  const [intProjectID, setProjectName] = useState(0);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Terms Of Payments
  const [termsofPayments, setTermsofPayments] = useState([]);
  // const [txtTermsOfPayments, setTermsofPayment] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 59,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          // debugger
          // const temp = response.data.result[
          //   response.data.result.findIndex(
          //     (i) => i.name === termsOfPayment
          //   )
          // ].id;

          // setTermsOfPayment(temp);

          setTermsofPayments(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // dispatch
  const [dispatch, setDispatch] = useState([]);
  const [txtDispatchThrough, setDispatchThrough] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 65,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setDispatch(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // UnitList
  const [unitsList, setUnitsList] = useState([]);
  const [units, setUnits] = useState("");
  tempRefs.current = false;
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 88,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setUnitsList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // Purchase
  const [Purchase, setPurchase] = useState([]);
  // const [txtPurchaseClassify, settxtPurchase] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 68,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setPurchase(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  // Vendor Details
  const [vendorDetails, setVendorDetails] = useState({});
  const [intVendorID, setVendorID] = useState(0);

  const handleVendor = (id) => {
    // setVendorID(e.target.value);
    // setValue("intVendorID", e.target.value);

    const data = {
      intType: 7,
      intvendorAutoID: id,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = vendorAPI;

    axios(config)
      .then(function (response) {
        setVendorDetails(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  tempRefs.current = false;

  // Purchase
  const [termsNames, setTermsNames] = useState([]);

  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = termsAndConditionsAPI;

      axios(config)
        .then(function (response) {
          setTermsNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  // const [termsName, setTermsName] = useState("");
  const [terms, setTermsandConditions] = useState("");
  const [termsDescription, setTermsDescription] = useState(null);

  const handleChangeterm = (event) => {
    setPOTerm(event.target.value);
    setTermsHeading(event.target.value);

    const data = {
      intType: 3,
      intID: event.target.value,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        setTermsandConditions(response.data.result[0]);
        setTermsDescription(response.data.result[0].description);

        // window.tinymce.init({
        //   selector: "#textarea",
        //   width: "100%",
        //   menubar: false,
        //   plugins:
        //     "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
        //   toolbar:
        //     "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        // });
        // setTimeout(function () {
        //   window.tinymce
        //     .get("textarea")
        //     .setContent(response.data.result[0].description);
        // }, 1150);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const initTinyMCE = () => {
      const textareaElement = document.getElementById("textarea");
      console.log("Textarea element:", textareaElement);
      if (textareaElement && termsDescription !== null) {
        window.tinymce.init({
          selector: "#textarea",
          width: "100%",
          menubar: false,
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar:
            "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        });

        setTimeout(function () {
          window.tinymce.get("textarea").setContent(termsDescription);
        }, 1500);
      }
    };

    const timeoutId = setTimeout(initTinyMCE, 500);

    return () => {
      clearTimeout(timeoutId);
      if (window.tinymce.get("textarea")) {
        window.tinymce.remove();
      }
    };
  }, [termsDescription]);

  // axios(config)
  // .then(function (response) {

  //   setTermsNames(response.data.result[0].title);
  //   window.tinymce.init({
  //     selector: "#textarea",
  //     width: "100%",
  //     menubar: false,
  //     plugins:
  //       "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
  //     toolbar:
  //       "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
  //   });
  //   setTimeout(function () {
  //     window.tinymce
  //       .get("textarea")
  //       .setContent(response.data.result[0].description);
  //   }, 1150);
  // })

  const [transportGST, setTransportGST] = useState(0);
  const [transportAmount, setTransportAmount] = useState(0);
  const [transportCharge, setTransportCharge] = useState(0);

  const [transportTotal, setTransportTotal] = useState(0);

  const handleTransportCharge = (price, v) => {
    if (v != "") {
      let GstValue = (price / 100) * parseInt(v);

      let total = isNaN(parseInt(GstValue) + parseInt(price))
        ? 0
        : parseInt(GstValue) + parseInt(price);

      setTransportCharge(total);
      // let newTotal = parseInt(totalamount) + (total - parseInt(transportTotal));
      // setTransportTotal(total);
      // setTransportAmount((prevAmount) => prevAmount + total);
      setTotalAmount(totalAmount + total);
    } else {
      // p=100 transportGST=12
      let GstValue = (price / 100) * parseInt(transportGST);
      // 12

      let total = isNaN(parseInt(GstValue) + parseInt(price))
        ? 0
        : parseInt(GstValue) + parseInt(price);
      // 112

      setTransportCharge(total);
      // let newTotal = parseInt(totalamount) + total - parseInt(transportTotal);
      // setTransportTotal(total);
      // setTransportAmount((prevAmount) => prevAmount + total);
      setTotalAmount(totalAmount + total);
    }
  };

  const [baseuploadagreement, setBaseUploadAgreement] = useState("");

  const uploadagreement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseUploadAgreement(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [companyNames, setCompanyNames] = React.useState("");
  // getting all companies
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 5,
        // intParentId: 42,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageCompaniesAPI;

      axios(config)
        .then(function (response) {
          setCompanyNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  // getting the PO data
  const [poNo, setPoNo] = useState(null);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 4,
        intPOId: POid,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = purchaseOrderAPI;

      axios(config)
        .then(function (response) {
          console.log(
            "⛔ ➡️ file: PurchaseOrderEdit.jsx:652 ➡️ response:",
            response.data.result
          );

          setPoNo(response.data.result[1][0].po_no);
          handleFormReset(response.data.result[1][0]);
          fetchProjectDetails(response.data.result[1][0].project_id);

          // roundUpToInteger()

          // setPoData(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const [projectDetails, setProjectDetails] = useState([]);

  // Fetching project details
  const fetchProjectDetails = (projectID) => {
    if (projectID === 0) {
      return;
    }

    const data = {
      intType: 2,
      intProjectID: projectID,
      intLimit: 1,
    };

    console.log("⛔ ➡️ file: PurchaseOrderView.jsx:116 ➡️ data:", data);

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = projectMasterAPI;

    axios(config)
      .then((response) => {
        setProjectDetails(response.data.result[0]);
        console.log(
          "⛔ ➡️ file: PurchaseOrderView.jsx:129 ➡️ response.data:",
          response.data
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [serviceList1, setServiceList1] = useState([]);

  // PO items data
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
        intPONo: POid,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = purchaseOrderItemsAPI;

      axios(config)
        .then(function (response) {
          setServiceList(response.data.result);
          setServiceList1(response.data.result);
          setDeletedList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const [orderType, setOrderType] = useState("");
  const [vendorName, setVendorName] = useState("");
  // const [companyName, setCompanyName] = useState("")
  const [project, setProject] = useState("");
  const [termsOfPayment, setTermsOfPayment] = useState(0);
  const [purchaseClassify, setPurchaseClassify] = useState("");
  const [termsHeading, setTermsHeading] = useState("");
  const [validUpto, setValidUpto] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );

  // const [transportGst, setTransportGst] = React.useState("")
  const [termBody, setTermBody] = React.useState("");

  const [comingFile, setComingFile] = React.useState("");

  const handleFormReset = (data) => {
    console.log(
      "🚀💻 ~ file: PurchaseOrderEdit.jsx:689 ~ handleFormReset ~ data:",
      data
    );

    let defaultValues = {};

    total = data.total_without_gst;
    cgst = data.total_cgst;
    igst = data.total_igst;
    sgst = data.total_sgst;
    handleVendor(data.vendor_id);
    setOrderType(data.order_type);
    setVendorName(data.vendor_id);
    setCompanyName(data.company_name);
    setProject(data.project_id);
    setTermsOfPayment(data.terms_of_payment);
    setPurchaseClassify(data.purchase_classfication);
    setTermsHeading(data.terms_heading);
    setTransportGST(data.transport_gst);
    setTotalWithoutGST(data.total_without_gst);
    setTotalAmount(data.total_with_gst);
    setTransportAmount(data.transport_amount);
    setTransportCharge(data.transport_charge);
    setValidUpto(data.po_validate_upto);
    setTermBody(data.terms);
    settotalCgst(data.total_cgst);
    setTotalSgst(data.total_sgst);
    setTotalIgst(data.total_igst);
    setTermsandConditions(data.terms);
    setTextSiteEng(data.site_engineer);
    setTermsDescription(data.terms);

    if (data.file != "" || data.file != null) {
      setBaseUploadAgreement(data.file);
    }

    defaultValues.txtSiteEngContact = data.site_eng_contact;
    defaultValues.txtOrderType = data.order_type;
    defaultValues.intVendorID = data.vendor_id;
    defaultValues.txtCompanyName = data.company_name;
    defaultValues.intProjectID = data.project_id;
    defaultValues.txtTermsOfPayments = data.terms_of_payment;
    defaultValues.txtQuotationNo = data.quotation_no;
    defaultValues.txtPurchaseClassify = data.purchase_classfication;
    defaultValues.txtPOValidTo = data.po_validate_upto;
    defaultValues.intTransportGST = data.transport_gst;
    defaultValues.intTransportAmount = data.transport_charge;
    defaultValues.intTransportCharge = data.transport_charge;
    defaultValues.txtTerms = data.terms;
    defaultValues.txtTermsHeading = data.terms_heading;

    setTimeout(function () {
      reset({ ...defaultValues });
    }, 3000);
  };

  // const editorRef = useRef(null);
  // useEffect(() => {
  //   window.tinymce.init({
  //     selector: "#textarea",
  //     width: "100%",
  //     menubar: false,
  //     setup: (editor) => {
  //       editorRef.current = editor;

  //       // Set the initial content of the editor
  //       editor.on('init', () => {
  //         editor.setContent(``);
  //       });
  //     },
  //     plugins:
  //       "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
  //     toolbar:
  //       "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
  //   })

  // }, [])

  const [PoTerm, setPOTerm] = React.useState(0);

  function onSubmit(data) {
    // editing text area items
    const isInvalid = (value) => value === null || value === 0 || value === "";

    if (
      isInvalid(data.txtOrderType) ||
      isInvalid(data.txtCompanyName) ||
      isInvalid(data.intVendorID) ||
      isInvalid(data.intProjectID) ||
      isInvalid(data.txtTermsOfPayments) ||
      isInvalid(data.txtPurchaseClassify) ||
      isInvalid(data.txtSiteEng)
    ) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Please enter the required fields",
          messageType: "error",
        })
      );
      return;
    }

    if (
      PoTerm == 0 &&
      (data.txtTermsHeading === null || data.txtTermsHeading === "")
    ) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Terms & Conditions is required",
          messageType: "error",
        })
      );
      return;
    }

    if (deletedList.length <= 0) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "At least 1 PO Item is required",
          messageType: "error",
        })
      );
      return;
    } else {
      const invalidIndex = deletedList.findIndex((item) => {
        return (
          isNaN(item.intLineItemTotalWithGST) ||
          item.intLineItemTotalWithGST === ""
        );
      });

      if (invalidIndex !== -1) {
        console.log(`Invalid item found at S.no ${invalidIndex + 1}`);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Fill all the details at S.no ${invalidIndex + 1}`,
            messageType: "error",
          })
        );
        return;
      }
    }

    let finalPoDesc = "";
    let poDesc = window.tinymce.get("textarea")?.getContent();
    if (poDesc) {
      let newPoDesc = poDesc.replaceAll("<p>", "");
      finalPoDesc = newPoDesc.replaceAll("</p>", "");
    }

    data.txtPONumber = poNo;
    data.txtFile = baseuploadagreement;
    data.lineItems = deletedList;
    // return
    data.intTotalWithoutGST = totalWithoutGST;
    data.intTotalIGST = totalIgst;
    data.intTotalSGST = totalSgst;
    data.intTotalCGST = totalCgst;

    // if (totalIgst > 0) {
    //   data.intTotalSGST = 0;
    //   data.intTotalCGST = 0;
    //   data.intTotalIGST = totalIgst;
    // } else if (totalCgst > 0 && totalSgst > 0) {
    //   data.intTotalIGST = 0;
    //   data.intTotalSGST = totalSgst;
    //   data.intTotalCGST = totalCgst;
    // }
    data.txtPOValidTo = validUpto;
    data.intTotalWithGST = totalamount;
    data.intCreatedBy = userData.emp_id;
    data.txtSiteEng = txtSiteEng;
    if (intVendorID) {
      data.intVendorID = intVendorID;
    } else {
      data.intVendorID = vendorName;
    }

    data.intStatus = 98;
    data.intTransportGst = transportGST;
    data.intTransportAmount = transportAmount;
    data.intTransportCharge = transportCharge;

    if (PoTerm != 0) {
      data.txtTermsHeading = PoTerm;
    } else {
      data.txtTermsHeading = termsHeading;
    }
    if (finalPoDesc) {
      data.txtTerms = finalPoDesc;
    } else {
      data.txtTerms = termBody;
    }
    data.txtCompanyName = companyName;
    data.intType = 6;
    data.intPOId = POid;

    if (deletedList.length > 0) {
      data.deletedListIds = deletedItems;

      console.log(
        "🚀💻 ~ file: PurchaseOrderEdit.jsx:119 ~ onSubmit ~ deletedList:",
        data
      );
    }

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    console.log(data);
    // return;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate(`/purchase-order/list`);
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [siteEngineer, setSiteEngineer] = useState([]);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 9,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          console.log(
            "🚀💻 ~ file: PurchaseOrder.jsx:408 ~ response:",
            response
          );

          setSiteEngineer(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const findPhoneNumber = (id) => {
    const contact = siteEngineer.find((person) => person.id === id);
    contact && setValue("txtSiteEngContact", contact.phone_no);
  };

  function calculateRoundingDifference(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);

    // Calculate the rounding difference
    const difference = roundedValue - value;

    return difference.toFixed(2);
  }

  function roundUpToInteger(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);

    // Append ".00" to the rounded value
    const formattedValue = roundedValue.toFixed(2);

    return formattedValue;
  }

  const [isCancelPopup, setIsCancelPopup] = useState(false);

  const handleCancelFile = async () => {
    setBaseUploadAgreement("");
    document.getElementById("agreementdocument").value = "";
    setIsCancelPopup(false);
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Purchase Order
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="text.primary">purchase order</Typography>
                <Typography color="text.primary">edit</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="secondary"
                className="button_first"
                href="/purchase-order/list"
              >
                List purchase order
              </LoadingButton>
              {/* <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton> */}
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid className="purchaseorder">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="popading" spacing={2}>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("txtOrderType")}
                    label="Order Type"
                    value={orderType}
                    onChange={(e) => {
                      setOrderType(e.target.value);
                      setValue("txtOrderType", e.target.value);
                    }}
                  >
                    <MenuItem value="1">Purchase Order</MenuItem>
                    <MenuItem value="2">Work Order</MenuItem>
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtOrderType?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Vendor Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vendorName}
                    label="Vendor Name"
                    {...register("intVendorID")}
                    onChange={(e) => {
                      handleVendor(e.target.value);
                      setVendorID(e.target.value);
                      setVendorName(e.target.value);
                      setValue("intVendorID", e.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 450, // Limit the height of the dropdown menu to 450px
                        },
                      },
                    }}
                  >
                    {vendorList &&
                      vendorList.length > 0 &&
                      vendorList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.name + " (" + row.id + ")"}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intVendorID?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Company Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyName}
                    label="Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValue("txtCompanyName", e.target.value);
                    }}
                  >
                    {companyNames &&
                      companyNames.length > 0 &&
                      companyNames.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.company_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtCompanyName?.message}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Project
                  </InputLabel>
                  <Select
                    labelId="Select Project"
                    id="demo-simple-select"
                    value={project}
                    label="Select Project"
                    onChange={(e) => {
                      setProject(e.target.value);
                      fetchProjectDetails(e.target.value);
                      setValue("intProjectID", e.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 450, // Limit the height of the dropdown menu to 450px
                        },
                      },
                    }}
                  >
                    {ProjectNameList.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intProjectID?.message}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="pomargin table_po">
              <Grid container spacing={2}>
                {/* Vendor Details */}
                <Grid item className="form-label" xs={12} lg={4} md={4}>
                  {vendorDetails.hasOwnProperty("name") && (
                    <Grid className="info_details">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "20px",
                        }}
                      >
                        Bill To
                      </Typography>
                      <Typography className="companyna">
                        {vendorDetails.name}
                      </Typography>
                      <Typography>{vendorDetails.mobile_no}</Typography>
                      <Typography>
                        {vendorDetails.street_address}, {vendorDetails.city}
                      </Typography>
                      <Typography>
                        {vendorDetails.pincode} {vendorDetails.state}
                      </Typography>
                      <Typography>India</Typography>
                      <Typography>GSTIN {vendorDetails.gst}</Typography>
                    </Grid>
                  )}
                </Grid>

                {/* Project Details */}
                <Grid item className="form-label" xs={12} lg={4} md={4}>
                  {projectDetails.hasOwnProperty("name") && (
                    <Grid className="info_details">
                      <Typography variant="h6" sx={{ fontSize: "20px" }}>
                        Place of Supply:
                      </Typography>
                      <Typography className="companyna">
                        {projectDetails.project_address}
                      </Typography>
                      <Typography>{projectDetails.city}</Typography>
                      <Typography>
                        {projectDetails.pincode} {projectDetails.state}
                      </Typography>
                      <Typography>India</Typography>
                    </Grid>
                  )}
                </Grid>

                {/* Other Details */}
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={4}
                  sx={{ border: "1px solid black", mt: 2 }}
                >
                  {/* Mode/Terms of Payment */}
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className="po_leble"
                  >
                    <Grid item xs={12}>
                      <Typography>Mode/Terms of Payment:</Typography>
                      <FormControl fullWidth size="small">
                        <Select
                          value={termsOfPayment}
                          onChange={(e) => {
                            setTermsOfPayment(e.target.value);
                            setValue("txtTermsOfPayments", e.target.value);
                          }}
                        >
                          {termsofPayments.map((row, index) => (
                            <MenuItem value={row.name} key={index}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography className="invalid-feedback">
                          {errors.txtTermsOfPayments?.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Supplier Ref./Quotation No. */}
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className="po_leble"
                  >
                    <Grid item xs={12}>
                      <Typography>Supplier Ref./Quotation No.:</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="familyname"
                        type="text"
                        {...register("txtQuotationNo")}
                      />
                    </Grid>
                  </Grid>

                  {/* Purchase Classification */}
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className="po_leble"
                  >
                    <Grid item xs={12}>
                      <Typography>Purchase Classification:</Typography>
                      <FormControl fullWidth size="small">
                        <Select
                          value={purchaseClassify}
                          onChange={(e) => {
                            setPurchaseClassify(e.target.value);
                            setValue("txtPurchaseClassify", e.target.value);
                          }}
                        >
                          {Purchase.map((row, index) => (
                            <MenuItem value={row.id} key={index}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography className="invalid-feedback">
                          {errors.txtPurchaseClassify?.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Site Engineer */}
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className="po_leble"
                  >
                    <Grid item xs={12}>
                      <Typography>Site Engineer:</Typography>
                      <FormControl fullWidth size="small">
                        <Select
                          value={txtSiteEng}
                          onChange={(e) => {
                            setTextSiteEng(e.target.value);
                            findPhoneNumber(e.target.value);
                            setValue("txtSiteEng", e.target.value);
                          }}
                        >
                          {siteEngineer && siteEngineer.length > 0 ? (
                            siteEngineer.map((row, index) => (
                              <MenuItem value={row.id} key={index}>
                                {row.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>No data found</MenuItem>
                          )}
                        </Select>
                        <Typography className="invalid-feedback">
                          {errors.txtPurchaseClassify?.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Site Engineer Contact */}
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className="po_leble"
                  >
                    <Grid item xs={12}>
                      <Typography>Site Engineer Contact:</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="siteEngineerContact"
                        type="text"
                        {...register("txtSiteEngContact")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <table className={`table`}>
                  <thead>
                    <th>
                      <Typography>S. No.</Typography>
                    </th>
                    <th>
                      <Typography>Description</Typography>
                    </th>
                    <th>
                      <Typography>Units </Typography>
                    </th>
                    <th>
                      <Typography>Rate </Typography>
                    </th>
                    <th>
                      <Typography>Total Amount</Typography>
                    </th>
                    <th>
                      <Typography>GST </Typography>
                    </th>
                    {vendorDetails.state != "TamilNadu" ? (
                      <>
                        <th>
                          <Typography>IGST </Typography>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>
                          <Typography>CGST </Typography>
                        </th>
                        <th>
                          <Typography>SGST </Typography>
                        </th>
                      </>
                    )}
                    <th>
                      <Typography>Total Amount + GST </Typography>
                    </th>
                    <th className="add_button">
                      <Button
                        variant="outlined"
                        onClick={handleServiceAdd}
                        startIcon={<AddIcon />}
                        color="success"
                      ></Button>
                    </th>
                  </thead>
                  <tbody>
                    {serviceList1?.map((singleService, index) => (
                      <tr className="table_padding">
                        <td className={`text-center`}>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td className="po_leble">
                          <input
                            name="txtItemName"
                            type="text"
                            id="txtItemName"
                            value={singleService.txtItemName}
                            onChange={(e) => handleServiceChange(e, index)}
                            required
                          />
                        </td>
                        <td className="po_leble">
                          <Select
                            name="txtUnits"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={singleService?.txtUnits}
                            defaultValue={singleService?.txtUnits}
                            onChange={(e) => {
                              setUnitsFlag(true);
                              handleServiceChange(e, index);
                            }}
                          >
                            {unitsList.length > 0 &&
                              unitsList.map((row, index) => (
                                <MenuItem value={row.id} key={index}>
                                  {row.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {/* {unitsFlag && ( */}
                          <TextField
                            name="intQuantity"
                            type="number"
                            value={singleService.intQuantity}
                            onChange={(e) => handleServiceChange(e, index)}
                          />
                          {/* )} */}
                        </td>
                        <td className="po_leble">
                          <TextField
                            name="intAmount"
                            type="number"
                            // {...register("intAmount")}
                            value={singleService.intAmount}
                            onChange={(e) => handleServiceChange(e, index)}
                          />
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithoutGST)
                              ? `0.00`
                              : twoDecimal(
                                  singleService?.intLineItemTotalWithoutGST
                                )}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              name="intGST"
                              id="demo-simple-select"
                              // value={singleService.intGST}
                              defaultValue={parseInt(singleService?.intGST)}
                              onChange={(e) => {
                                handleServiceChange(e, index);
                              }}
                            >
                              <MenuItem value={`5`} key={index}>
                                GST 5%
                              </MenuItem>
                              <MenuItem value={`12`} key={index}>
                                GST 12%
                              </MenuItem>
                              <MenuItem value={`18`} key={index}>
                                GST 18%
                              </MenuItem>
                              <MenuItem value={`28`} key={index}>
                                GST 28%
                              </MenuItem>
                            </Select>
                            <Typography className="invalid-feedback">
                              {errors.intGST?.message}
                            </Typography>
                          </FormControl>
                        </td>
                        {vendorDetails.state != "TamilNadu" ? (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intIGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intIGST)}
                              </Typography>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intCGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intCGST)}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intSGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intSGST)}
                              </Typography>
                            </td>
                          </>
                        )}
                        {/* <td className="po_leble">
                          <Typography>{singleService.intSGST}</Typography>
                        </td> */}
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithGST)
                              ? `0.00`
                              : twoDecimal(
                                  singleService.intLineItemTotalWithGST
                                )}
                          </Typography>
                        </td>
                        <td className="cancel_button">
                          {serviceList1.length !== 1 && (
                            <Button
                              variant="outlined"
                              onClick={(e) => {
                                handleServiceChange(e, index);
                                handleServiceRemove(index);
                              }}
                              startIcon={<DeleteIcon />}
                              color="error"
                            ></Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className="tbody_col">
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Total</Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={total}>
                          {isNaN(total) ? `0.00` : twoDecimal(total)}
                        </Typography>
                      </td>
                    </tr>
                    {vendorDetails.state != "TamilNadu" ? (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography>IGST </Typography>
                          </td>
                          <td>
                            <Typography value={totalIgst}>
                              {isNaN(totalIgst)
                                ? `0.00`
                                : twoDecimal(totalIgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography>CGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalCgst}>
                              {isNaN(totalCgst)
                                ? `0.00`
                                : twoDecimal(totalCgst)}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography>SGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalSgst}>
                              {isNaN(totalSgst)
                                ? `0.00`
                                : twoDecimal(totalSgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={5}>
                        <Typography>Transport Charges</Typography>
                      </td>
                      <td className="po_leble">
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            name="intTransportGST"
                            id="demo-simple-select"
                            {...register("intTransportGST")}
                            value={transportGST}
                            onChange={(e) => {
                              setValue("intTransportGST", e.target.value);
                              setTransportGST(e.target.value);
                            }}
                          >
                            <MenuItem value={`0`} key={1}>
                              GST 0%
                            </MenuItem>
                            <MenuItem value={`5`} key={1}>
                              GST 5%
                            </MenuItem>
                            <MenuItem value={`12`} key={2}>
                              GST 12%
                            </MenuItem>
                            <MenuItem value={`18`} key={3}>
                              GST 18%
                            </MenuItem>
                            <MenuItem value={`28`} key={4}>
                              GST 28%
                            </MenuItem>
                          </Select>
                          <Typography className="invalid-feedback">
                            {errors.intGST?.message}
                          </Typography>
                        </FormControl>
                      </td>
                      <td className="po_leble">
                        <TextField
                          name="intTransportAmount"
                          type="number"
                          value={transportAmount}
                          {...register("intTransportAmount")}
                          onChange={(e) => {
                            setTransportAmount(e.target.value);
                            handleTransportCharge(e.target.value, "");
                          }}
                        />
                      </td>
                      <td colSpan={5}>
                        <Typography value={transportCharge}>
                          {isNaN(transportCharge)
                            ? `0.00`
                            : twoDecimal(transportCharge)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Rounded off </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : calculateRoundingDifference(totalamount)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Total amount with GST </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : twoDecimal(totalamount)}
                        </Typography>
                      </td>
                    </tr>

                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Rounded off </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : calculateRoundingDifference(totalamount)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          Total Payable Amount (Rs.)
                        </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography
                          value={totalamount}
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {isNaN(totalamount)
                            ? `0.00 /-`
                            : `${roundUpToInteger(totalamount)} /-`}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              textAlign="left"
              className="po_lebleterm"
            >
              <FormControl className="form_control" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Terms & Conditions
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={termsHeading}
                  onChange={handleChangeterm}
                  input={<OutlinedInput label="Terms & Conditions" />}
                  MenuProps={MenuProps}
                >
                  {termsNames.map((terms) => (
                    <MenuItem key={terms.title} value={terms.id}>
                      <ListItemText primary={terms.title} onClick={() => {}} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {termsDescription && (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                textAlign={`right`}
                display={`flex`}
                justifyContent={`flex-end`}
              >
                <TextField
                  fullWidth
                  id="textarea"
                  // value={termBody}
                  {...register("txtTerms")}
                  // onchange={handleTextArea()}
                />
              </Grid>
            )}

            <Grid></Grid>
            <Grid item className="form-lable" xs={12} lg={4} md={4}>
              <label> Document : </label>
              <br />
              <small className="" style={{ fontSize: "12px" }}>
                Allowed Only *.jpeg, *.jpg, *.png, *.pdf
              </small>
              <br></br>
              <Box display={`flex`}>
                <Button variant="contained" component="label">
                  <input
                    id="agreementdocument"
                    name="agreementdocument"
                    type="file"
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    fullWidth
                    {...register("txtFile")}
                    onChange={(e) => {
                      uploadagreement(e);
                    }}
                  />
                </Button>
                {baseuploadagreement != "" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsCancelPopup(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid></Grid>
            {baseuploadagreement != "" && (
              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">Document</Typography>

                  {baseuploadagreement.endsWith(".pdf") ? (
                    <iframe
                      src={baseuploadagreement}
                      title="document"
                      width="100%"
                      height="600"
                    ></iframe>
                  ) : baseuploadagreement.startsWith(
                      "data:application/pdf;base64,"
                    ) ? (
                    <iframe
                      src={`data:application/pdf;base64,${
                        baseuploadagreement.split(",")[1]
                      }`}
                      title="document"
                      width="100%"
                      height="600"
                    ></iframe>
                  ) : (
                    <img
                      src={baseuploadagreement}
                      alt="document"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <div className="empty-space-20"></div>
            <Grid item xs={12} md={12} lg={12} textAlign="right">
              <Button variant="primary" className={`btn-primary`} type="submit">
                Submit
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={isCancelPopup}
        onClose={() => setIsCancelPopup(false)}
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
          <Typography
            variant="h5"
            style={{
              color: "#FF6868",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Warning!!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <Typography variant="h6">
            Would you like to remove the attachment?
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#f44336", fontWeight: "bold" }}
          >
            **This step cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          <Button color="success" onClick={handleCancelFile}>
            Yes
          </Button>
          <Button color="error" onClick={() => setIsCancelPopup(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default PurchaseOrderEdit;
